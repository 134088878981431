import React from 'react';
import Splash from '../../components/splash/Splash';
import Content from '../../components/content/Content';

const NotFound = () => {
    return (
        <React.Fragment>
            <Splash />
            <Content imageFloat='left' title='404 Not Found'>
                <p>Die angeforderte URL wurde nicht gefunden.</p>
            </Content>
        </React.Fragment>
    );
};

export default NotFound;